import { FlagIcon } from '@heroicons/vue/24/outline';
import { defineStore } from 'pinia'
import { ref, type Ref } from 'vue'

export const useUser = defineStore('useUser', () => {
    
    const userStore = ref();

    const acceptedCharte: Ref<undefined | boolean> = ref(undefined)
    const filledInfos: Ref<undefined | boolean>  = ref(undefined)

    function setUser(user:any){
        userStore.value = user
    }
    function getUser():any{
        return userStore.value
    }

    function setAcceptedCharte(v:boolean){
        acceptedCharte.value = v
    }

    function setFilledInfos(v:boolean){
       filledInfos.value = v
    }

    return { setUser, getUser,userStore, setAcceptedCharte, acceptedCharte, setFilledInfos, filledInfos }
});