import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useOwncloudStore = defineStore('useOwncloudStore', () => {
    const iframe = ref();
    const visible = ref(false)
    const loaded = ref(false)
    const errLoading = ref(false)
    const activeIframe = ref(false);
    const url = import.meta.env.VITE_OWNCLOUD_URL
  
    return { iframe, url, visible, loaded,errLoading,activeIframe}
});


export const useForumStore = defineStore('useForumStore', () => {
    const iframe = ref();
    const visible = ref(false)
    const loaded = ref(false)
    const errLoading = ref(false)
    const activeIframe = ref(false);
    const url = ref(import.meta.env.VITE_FORUM_URL)
  
    return { iframe, url, visible, loaded, errLoading,activeIframe}
});


export const useUtilsStore = defineStore('useUtilsStore', () => {
    function checkIframeValidity(iframe: any) {
        fetch(iframe.url)
        .then(response => {
    
            if(response.status !== 200){
                iframe.errLoading = true;
                iframe.loaded = true;

            }
            
        }).catch(err => {
            iframe.errLoading = true;
            iframe.loaded = true;
        });
    };
  
    return { checkIframeValidity }
});