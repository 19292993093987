<script setup lang="ts">
import AuthService from "@/services/AuthService";
import {userManager} from "../plugin";
import { useUser } from '@/stores/user'

userManager.signinRedirect().then(async() => {
  console.log("redirected");

}).catch((err: any) => {
  console.error(err);
});
</script>
