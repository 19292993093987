<script setup lang="ts">
import { ref } from 'vue';


const props = defineProps<{
  name: string,
  id: string,
  label: string,
  placeholder?: string,
  disabled?: boolean,
  defaultValues?: any,
  symbol?: string,
  type?: string,
  required?: boolean
}>()

const value = ref(props.defaultValues ?? '');

function setValue(newValue: any) {
  value.value = newValue;
}

defineExpose({
   props,
   value,
    setValue
});
</script>

<template>
<div class="w-full">
  <label :for="String(props.id)" class="block text-sm font-medium leading-6 text-gray-900"><b v-if="props.required" class="text-red-600">*</b><b>{{ props.name }}</b> {{ props.label }}</label>
  <div class="relative mt-2 rounded-md shadow-sm">
    <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
      <span class="text-gray-500 sm:text-sm">{{ props.symbol ?? 'Az' }}</span>
    </div>
    <input v-model="value" :placeholder="props.placeholder" :name="props.name" :disabled="props.disabled" :id="String(props.id)" :type="props.type ?? 'text'" class="block w-full rounded-md border-0 py-1.5 pl-10 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 disabled:bg-gray-100" />
  </div>
</div>

</template>