import http from "../api-http-common";

class FormsInfoService {
  post(data:any,route:string) {
    return http.post("/forms/info/"+route, data);
  }

  get(){
    return http.get("/forms/info");
  }
}

export default new FormsInfoService();
