import './assets/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'
import CKEditor from '@ckeditor/ckeditor5-vue';

import { oidcPlugin } from './oidc/plugin'


try {
  const pinia = createPinia()
  const app = createApp(App)
  app.use(CKEditor)
  app.use(pinia)
  app.use(oidcPlugin, {router})
  app.use(router)
  app.mount('#app')
} catch (error) {
  console.log(error)
}
