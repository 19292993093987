  
<script setup lang="ts">
  import { ref } from 'vue'
  import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
  import { CheckIcon } from '@heroicons/vue/24/outline'
  import GeneratedInputText from './inputs/generated/GeneratedInputText.vue';
  import GeneratedInputTextArea from './inputs/generated/GeneratedInputTextArea.vue';
  import FeedBackService from '@/services/FeedBackService';
  import generatedTypes from '@/components/alert/types';

  const open = ref(false)
  const props = defineProps<{
    addAlertParent: (type:string,message:string) => void;
    connectedUser: any
  }>()
  defineExpose({ open })


  const alertMsg = ref([] as any[])
  function addAlert(type:string,message:string){
      alertMsg.value.push({type:type,message:message});
  }

  const title = {
    id: "title",
    name: " ",
    label: "Objet :",
    placeholder: "Objet du mail",
  }

  const firstName = {
    id: "firstName",
    name: " ",
    label: "Prénom :",
    placeholder: "Prénom",
  }

  const lastName = {
    id: "lastName",
    name: " ",
    label: "Nom :",
    placeholder: "Nom",
  }

  const content = {
    id: "content",
    name: " ",
    label: "Contenu :",
    placeholder: "Contenue du mail",
  }

  const refTitle = ref(null);
  const refFirstName = ref(null);
  const refLastName = ref(null);
  const refContent = ref(null);




  async function submitFeedback() {
    // console.log('Feedback submitted',refContent.value.actualValue.value, refTitle.value.value)
    // @ts-ignore
    if(!(refTitle?.value as any)?.value || !refContent?.value?.actualValue?.value){
      addAlert('ErrorComponent', 'Veuillez remplir tous les champs !')
        
        return;
    }
    
    
    const data: { subject: string; content: string; data: { agent: string; firstName?: string; lastName?: string } } = {
      subject: (refTitle.value! as any).value,
      content: (refContent.value! as any).actualValue.value,
      data: { 
        agent: window.navigator.userAgent   
      }
    }

    if(!props.connectedUser){
      if(!(refFirstName?.value as any)?.value || !(refLastName?.value as any)?.value){
        addAlert('ErrorComponent', 'Veuillez remplir tous les champs !')
        return;
      }
      data.data = {
        ...data.data,
        firstName: (refFirstName?.value as any)?.value,
        lastName: (refLastName?.value as any)?.value
      }
    }

    let res;
    try {
      res = await FeedBackService.post(data)
    } catch (error) {
      addAlert('ErrorComponent', 'Une erreur est survenue ! Veuillez réessayer plus tard')
      return;
    }
    if(res.status == 200){
      (refTitle.value! as any).value = '';
      (refContent.value! as any).actualValue.value = ''
      props.addAlertParent('SuccessComponent', 'Votre message a bien été envoyé !')
      open.value = false;
      return;
    }
  }

</script>

<template>

    <TransitionRoot as="template" :show="open">
      <Dialog as="div" class="relative z-50" @close="open = false">
        <TransitionChild as="template" @click="open = false" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>
  
        <div class="fixed inset-0 z-50 w-screen overflow-y-auto">
          <div class="absolute w-full">
              <component  v-for="(value, key) in alertMsg" :is="generatedTypes.get(value.type)" :message="value.message" :key="key" class=" animate-notification"/>
          </div>
          <div class="flex min-h-full items-end justify-center md:p-4 text-center sm:items-center sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg sm:p-6">
                <div class="grid grid-cols-1 gap-10">

                  <GeneratedInputText v-bind="title" ref="refTitle"/>

                  <div v-if="!(props.connectedUser)" class="-mt-5 sm:-mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <GeneratedInputText v-bind="firstName" ref="refFirstName"/>
                    <GeneratedInputText v-bind="lastName" ref="refLastName"/>
                  </div>
                  <GeneratedInputTextArea v-bind="content" ref="refContent" class="-mt-5" />
                  <div class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button type="button" class="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:col-start-2" @click="submitFeedback">Envoyer</button>
                    <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0" @click="open = false" ref="cancelButtonRef">Annuler</button>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </template>
