import Callback from "@/oidc/components/CallbackComponent.vue";
import Login from "@/oidc/components/LoginComponent.vue";

import { useGlobalStore, usePermissions } from '@/stores/utils'

import { useUser } from "@/stores/user";
import AirtableService from "@/services/AirtableService";
import { storeToRefs } from "pinia";
import AuthService from "@/services/AuthService";

export async function routeManager(router: any,userManager: any, getLocalUser: any){


    router.addRoute({ path: '/callback', component: Callback })
    router.addRoute({ path: '/login', component: Login })
    router.addRoute({ path: '/logout', redirect: '/' })

    
    const perm: any = usePermissions;
    const { setUser } = useUser();
    const { filledInfos, acceptedCharte } = storeToRefs(useUser());

    router.beforeEach(async (to:any, from:any, next:any) => {
        
        

        const permission: string | undefined = to.meta.permission as string | undefined
        if(!permission){
            next();
            return
        }

        const access = perm.disconnected.includes(permission)

        if(!document.cookie.includes('profanToken') && getLocalUser && !access){
            const renewUser = await userManager.signinSilent();
            
            renewUser ?? router.push('login');
        }



        const user = await userManager.getUser();

        if(user && (!filledInfos.value || !acceptedCharte.value)){
              try {
                let pres = await AuthService.getProfile();
                    if (pres.status == 200) {
                      useUser().setAcceptedCharte(pres.data.hasAcceptedCharte);
                      useUser().setFilledInfos(pres.data.hasFilledInfos);
                    }
                } catch (error) {
                console.error(error);
                // userManager.renewUser()
              }
        }
        setUser(user)
        
        useGlobalStore().GlobalAppLoading = true
        document.title = `${String(to.name)} | ${import.meta.env.VITE_APP_TITLE}`

        
        if (user && filledInfos.value === false && !to.path.includes('/inscription')) {

            if (user.profile.groups.includes('chefetablissement')) {
              window.location.assign(window.location.origin + '/inscription/chefetablissement')
            }
            if (user.profile.groups.includes('formateur') || user.profile.groups.includes('forme')) {
              window.location.assign(window.location.origin + '/inscription/formateur')
            }
            if (user.profile.groups.includes('inspecteur')) {
              window.location.assign(window.location.origin + '/inscription/inspecteur')
            }
            if (user.profile.groups.includes('directeureafc')) {
                window.location.assign(window.location.origin + '/inscription/directeureafc')
            }

            // localStorage.setItem('userInfos', JSON.stringify(user.profile.userInfos));
        }


        if(!(user?.profile?.groups)){
            access ? next(): router.push('404');
            return
        }

        if(permission && user?.profile?.groups){
            const userPerms = user.profile.groups.map((el:string) => el = perm[el]).flat()




            if(userPerms.includes(permission)){

                if(to.path.includes('airtable')){
                    // alert(to.path)
                    try {
                        const req = await AirtableService.postTracking(
                            {
                                type: 'navigation',
                                data: {
                                    url: to.path,
                                    action: 'navigate',
                                    idResource: (to.params.id && to.params.id !== 'new') ? to.params.id : null,
                                    route: to.name,
                                }
                            }
                        )
                    }
                    catch (e) {
                        console.error(e)
                    }

                }

                next();
                return;
            };
            //else
            router.push('404')

        }
        next();
        return;

    })
}