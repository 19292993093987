<script setup lang="ts">
import GeneratedInputText from '@/components/inputs/generated/GeneratedInputText.vue';
import GeneratedListBox from '@/components/inputs/generated/GeneratedListBox.vue'
import GeneratedComboBox from '@/components/inputs/generated/GeneratedComboBox.vue'
import GeneratedInputNumber from '../inputs/generated/GeneratedInputNumber.vue';
import generatedTypes from '@/components/alert/types';
import speOrigine from '@/assets/formsJsons/spetialite.json'


const props = defineProps<{
  userProfile: any
}>()

const Filieres: Array<{ id: number; name: string }> = [
  { id: 1, name: 'Métiers de la construction durable du bâtiment et des travaux publics' },
  { id: 2, name: 'Métiers de la gestion administrative, du transport et de la logistique ' },
  { id: 3, name: 'Métiers de la relation client' },
  { id: 4, name: 'Métiers de l’aéronautique' },
  { id: 5, name: 'Métiers des industries graphiques et de la communication' },
  { id: 6, name: 'Métiers de l’hôtellerie-restauration' },
  { id: 7, name: 'Métiers de l’alimentation' },
  { id: 8, name: 'Métiers des études et de la modélisation numérique du bâtiment' },
  { id: 9, name: 'Métiers de la beauté et du bien-être' },
  { id: 10, name: 'Métiers de la réalisation d’ensembles mécaniques et industriels' },
  { id: 11, name: 'Métiers des transitions numérique et énergétique' },
  { id: 12, name: 'Métiers de la maintenance des matériels et des véhicules' },
  { id: 13, name: "Métiers du pilotage et de la maintenance d'installations automatisées" },
  { id: 14, name: 'Métiers de l’agencement, de la menuiserie et de l’ameublement' },
  { id: 15, name: 'Accompagnement, soins et services à la personne' },
  { id: 16, name: 'Animation, enfance et personnes âgées' },
  { id: 17, name: 'Artisanat et métiers d’art' },
  { id: 18, name: 'Métiers de la mode' },
  { id: 19, name: 'Métiers de la sécurité' },
  { id: 20, name: 'Autres' }
]

const objectFilieres = {
  id: "idfiliereenseignement",
  name: " ",
  label: "Filières d'enseignement :",
  values: Filieres,
  multiple: true
}
const objectSpeOrigine = {
  id: "idspeorigine",
  name: " ",
  label: "Spécialité d’origine :",
  values: speOrigine
}
</script>



<template>

      <GeneratedComboBox class="col-span-full" v-bind="objectSpeOrigine" :default-values="userProfile.idspeorigine" />
      <GeneratedListBox class="col-span-full" v-bind="objectFilieres" :default-values="userProfile.idfiliereenseignement" />
      <div class="relative flex gap-x-3 col-span-full">
        <div class="flex h-6 items-center">
          <input id="isProfanUser2" name="isProfanUser2" type="checkbox"
            class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-transparent" :checked="userProfile.isProfanUser2" />
        </div>
        <div class="text-sm leading-6">
          <label for="isProfanUser2" class="font-medium text-gray-900">A participé à ProFAN (2017-2021)</label>
        </div>
      </div>
      <div class="relative flex gap-x-3 col-span-full">
        <div class="flex h-6 items-center">
          <input id="isEAFCpilote" name="isEAFCpilote" type="checkbox"
            class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-transparent" :checked="userProfile.isEAFCpilote" />
        </div>
        <div class="text-sm leading-6">
          <label for="isEAFCpilote" class="font-medium text-gray-900">Pilotage de formations à l'EAFC</label>
        </div>
      </div>


</template>