<script setup lang="ts">
import GeneratedInputText from '@/components/inputs/generated/GeneratedInputText.vue';
import GeneratedListBox from '@/components/inputs/generated/GeneratedListBox.vue'
import GeneratedComboBox from '@/components/inputs/generated/GeneratedComboBox.vue'
import GeneratedInputNumber from '../inputs/generated/GeneratedInputNumber.vue';
import generatedTypes from '@/components/alert/types';
import speOrigine from '@/assets/formsJsons/spetialite.json'
import acades from '@/assets/formsJsons/academy.json'
import numEtablissements from '@/assets/formsJsons/etablissements.json'
import { ref, type Ref } from 'vue'



const props = defineProps<{
  userProfile: any
}>()

interface BasicObject {
  name: string
  id: number
  avatar: string
}

const load = ref(false);
const notfoundNumEtabl = ref(false);
const metExecTime = ref();


const disciplines: Array<{ id: number; name: string; }> = [
  {
    id: 1,
    name: 'Français, Histoire-géographie et enseignement moral et civique'
  },
  {
    id: 2,
    name: 'Mathématiques, Sciences'
  },
  {
    id: 3,
    name: 'Langue vivante A'
  },
  {
    id: 4,
    name: 'Langue vivante B'
  },
  {
    id: 5,
    name: 'Arts appliqués et culture artistique'
  },
  {
    id: 6,
    name: 'EPS'
  },
  {
    id: 7,
    name: 'Enseignement professionnel'
  },
  {
    id: 8,
    name: 'Enseignement professionnel et enseignement général (français/ maths/ sciences) en co-intervention'
  },
  {
    id: 9,
    name: 'Réalisation d’un chef d’œuvre'
  },
  {
    id: 10,
    name: 'Prévention santé environnement'
  },
  {
    id: 11,
    name: 'Economie-droit / Economie-gestion'
  }
];
const Filieres: Array<{ id: number; name: string; }> = [
  { id: 1, name: "Métiers de la construction durable du bâtiment et des travaux publics" },
  { id: 2, name: "Métiers de la gestion administrative, du transport et de la logistique " },
  { id: 3, name: "Métiers de la relation client" },
  { id: 4, name: "Métiers de l’aéronautique" },
  { id: 5, name: "Métiers des industries graphiques et de la communication" },
  { id: 6, name: "Métiers de l’hôtellerie-restauration" },
  { id: 7, name: "Métiers de l’alimentation" },
  { id: 8, name: "Métiers des études et de la modélisation numérique du bâtiment" },
  { id: 9, name: "Métiers de la beauté et du bien-être" },
  { id: 10, name: "Métiers de la réalisation d’ensembles mécaniques et industriels" },
  { id: 11, name: "Métiers des transitions numérique et énergétique" },
  { id: 12, name: "Métiers de la maintenance des matériels et des véhicules" },
  { id: 13, name: "Métiers du pilotage et de la maintenance d'installations automatisées" },
  { id: 14, name: "Métiers de l’agencement, de la menuiserie et de l’ameublement" },
  { id: 15, name: "Accompagnement, soins et services à la personne" },
  { id: 16, name: "Animation, enfance et personnes âgées" },
  { id: 17, name: "Artisanat et métiers d’art" },
  { id: 18, name: "Métiers de la mode" },
  { id: 19, name: "Métiers de la sécurité" },
  { id: 20, name: "Autres" }
];
const niveauxEnseign: Array<{ id: number; name: string; }> = [
  { id: 1, name: "CAP" },
  { id: 2, name: "Baccalauréat professionnel" },
  { id: 3, name: "BTS" }
];
const contexteEnseign: Array<{ id: number; name: string; }> = [
  { id: 1, name: "Scolaire - En formation initiale" },
  { id: 2, name: "Apprentissage - En formation initiale" },
  { id: 3, name: "En formation continue" },
];
const contexteFormation: Array<{ id: number; name: string; }> = [
  { id: 1, name: "En EAFC" },
  { id: 2, name: "En INSPE" }
];
const typeFormateurEAFC: Array<{ id: number; name: string; }> = [
  { id: 1, name: "Formateur disciplinaire" },
  { id: 2, name: "Formateur trans-disciplinaire" }
]

const formateurEAFC: Ref<BasicObject | undefined> = ref(props.userProfile.formateurEAFC ?? false);



const objectNumEtabl = {
  id: "idNumEtabl",
  name: " ",
  label: "Numéro d'Etablissement d'Affection :",
  values: numEtablissements,
  limit: 200
}
const objectRecrut = {
  id: "idRecrut",
  name: " ",
  label: "Concours et Section de recrutement :",
  values: speOrigine,
}
const objectMetExec = {
  id: "idMetExec",
  name: " ",
  label: "Avez-vous exercé le/les métiers auxquels vous préparez vos élèves ?",
  values: [
    {
      id: 1,
      name: "Oui"
    },
    {
      id: 2,
      name: "Non"
    }
  ],
}
const objectMetExecTime = {
  id: "idMetExecTime",
  name: " ",
  label: "Sur une durée ?",
  values: [
    {
      id: 1,
      name: "1-5 ans"
    },
    {
      id: 2,
      name: "6-10 ans"
    },
    {
      id: 3,
      name: "11-15 ans"
    },
    {
      id: 4,
      name: "16-20 ans"
    },
    {
      id: 5,
      name: ">20 ans"
    }
  ],
}
const objectDisEnseign = {
  id: "idDisEnseign",
  name: " ",
  label: "Discipline(s) d'enseignement :",
  values: disciplines
}
const objectFilieres = {
  id: "idfiliereenseignement",
  name: " ",
  label: "Filières d'enseignement :",
  values: Filieres,
  multiple: true
}
const objectNivEnseign = {
  id: "idNivEnseign",
  name: " ",
  label: "Niveaux d'enseignement :",
  values: niveauxEnseign,
  multiple: true
}
const objectCtxEnseign = {
  id: "idCtxEnseign",
  name: " ",
  label: "Contexte d'enseignement :",
  values: contexteEnseign,
  multiple: true
}
const objectFormateurEafc = {
  id: "idFormateurEafc",
  name: "",
  label: "Type Formateur EAFC :",
  values: typeFormateurEAFC,
}
const objectCtxFormation = {
  id: "idCtxFormation",
  name: " ",
  label: "Contexte de formation :",
  values: contexteFormation,
  multiple: true

}



</script>



<template>

      <div v-if="notfoundNumEtabl" class="w-full col-span-full">
        <label for="given_name" class="block text-sm font-medium leading-6 text-gray-900">Numéro d'Etablissement
          d'Affection :</label>
        <div class="relative mt-2 rounded-md shadow-sm">
          <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <span class="text-gray-500 sm:text-sm">Az</span>
          </div>
          <input type="text" name="idNumEtabl" id="idNumEtabl"
            class="block w-full rounded-md border-0 py-1.5 pl-10 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            placeholder="Saisir le numéro" :default-values="userProfile.idNumEtabl" />
        </div>
      </div>
      <GeneratedComboBox v-else v-bind="objectNumEtabl" class="col-span-full" :default-values="userProfile.idNumEtabl"/>

      <button v-if="!notfoundNumEtabl" @click="notfoundNumEtabl = true" type="button"
        class="rounded bg-white px-2 py-1 w-min whitespace-nowrap text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 col-span-full">
        Je ne trouve pas mon numéro
      </button>



      <GeneratedListBox v-bind="objectRecrut" class="col-span-full" :default-values="userProfile.idRecrut" />
      <GeneratedListBox v-bind="objectMetExec" ref="metExecTime" class="col-span-full" :default-values="userProfile.idMetExec" />
      <GeneratedListBox v-bind="objectMetExecTime" class="col-span-full"
        v-if="metExecTime && metExecTime.selectedValue && metExecTime.selectedValue.name == 'Oui'" :default-values="userProfile.idMetExecTime" />

      <GeneratedListBox v-bind="objectDisEnseign" class="col-span-full" :default-values="userProfile.idDisEnseign" />

      <div class="relative flex gap-x-3 col-span-full">
        <div class="flex h-6 items-center">
          <input id="co-intervention" name="comments" type="checkbox"
            class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-transparent" :checked="userProfile['co-intervention']" />
        </div>
        <div class="text-sm leading-6">
          <label for="co-intervention" class="font-medium text-gray-900">Enseignement en co-intervention</label>
        </div>
      </div>
      <div class="relative flex gap-x-3 col-span-full">
        <div class="flex h-6 items-center">
          <input id="interventions" name="comments" type="checkbox"
            class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-transparent" :checked="userProfile.interventions" />
        </div>
        <div class="text-sm leading-6">
          <label for="interventions" class="font-medium text-gray-900">Intervention dans le cadre du chef d’œuvre</label>
        </div>
      </div>

      <GeneratedListBox v-bind="objectFilieres" class="col-span-full" :default-values="userProfile.idfiliereenseignement" />

      <GeneratedListBox v-bind="objectNivEnseign" class="col-span-full" :default-values="userProfile.idNivEnseign" />
      <GeneratedListBox v-bind="objectCtxEnseign" class="col-span-full" :default-values="userProfile.idCtxEnseign" />

      <div class="relative flex gap-x-3 col-span-full">
        <div class="flex h-6 items-center">
          <input id="isProfanUser" name="isProfanUser" type="checkbox"
            class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-transparent" :checked="userProfile.isProfanUser" />
        </div>
        <div class="text-sm leading-6">
          <label for="isProfanUser" class="font-medium text-gray-900">A participé à ProFAN (2017-2021)</label>
        </div>
      </div>
      
      <div class="relative flex gap-x-3 col-span-full">
        <div class="flex h-6 items-center">
          <input id="formateurEAFC" name="comments" type="checkbox" v-model="formateurEAFC"
            class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-transparent" :checked="userProfile.formateurEAFC" />
        </div>
        <div class="text-sm leading-6">
          <label for="formateurEAFC" class="font-medium text-gray-900">Déjà formateur à l’EAFC</label>
        </div>

      </div>
      <GeneratedListBox v-if="formateurEAFC" v-bind="objectFormateurEafc" class="col-span-full" :default-values="userProfile.idFormateurEafc" />
      <div class="col-span-full">
        <label for="anneeexpformateur" class="block text-sm font-medium leading-6 text-gray-900">Experience de
          formateur:</label>
        <div class="relative mt-2 rounded-md shadow-sm">
          <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <span class="text-gray-500 sm:text-sm">0-9</span>
          </div>
          <input type="number" name="anneeexpformateur" id="anneeexpformateur" :min="1" :max="10"
            class="block w-full rounded-md border-0 py-1.5 pl-10 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            placeholder="5" :value="userProfile.anneeexpformateur" />
        </div>
      </div>
      <div class="relative flex gap-x-3 col-span-full">
        <div class="flex h-6 items-center">
          <input id="CAFFA" name="CAFFA" type="checkbox"
            class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-transparent" :checked="userProfile.CAFFA" />
        </div>
        <div class="text-sm leading-6">
          <label for="CAFFA" class="font-medium text-gray-900">Détention du CAFFA</label>
        </div>
      </div>
      <GeneratedListBox v-bind="objectCtxFormation" class="col-span-full" :default-values="userProfile.idCtxFormation" />

      <div class="col-span-full">
        <label for="formationSuivie" class="block text-sm font-medium leading-6 text-gray-900">Formations <u>suivies</u>
          en tant que formateur:</label>
        <div class="relative mt-2 rounded-md shadow-sm">
          <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <span class="text-gray-500 sm:text-sm">Az</span>
          </div>
          <input type="text" name="formationSuivie" id="formationSuivie"
            class="block w-full rounded-md border-0 py-1.5 pl-10 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            placeholder="Nom de(s) formation(s) suivie(s)" :value="userProfile.formationSuivie" />
        </div>

      </div>
      <div class="col-span-full">
        <label for="formationAssuree" class="block text-sm font-medium leading-6 text-gray-900">Formations <u>assurées</u>
          en tant que formateur:</label>
        <div class="relative mt-2 rounded-md shadow-sm">
          <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <span class="text-gray-500 sm:text-sm">Az</span>
          </div>
          <input type="text" name="formationAssuree" id="formationAssuree"
            class="block w-full rounded-md border-0 py-1.5 pl-10 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            placeholder="Nom de(s) formation(s) assurée(s)" :value="userProfile.formationAssuree" />
        </div>

      </div>

</template>