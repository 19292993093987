<script setup lang="ts">
import { RouterView } from 'vue-router'
import BaseLayoutVue from './layout/BaseLayout.vue';
import { useUser } from '@/stores/user';
import { onMounted, ref } from 'vue';
import Maintenance from './components/Maintenance.vue';


const maintenance = ref(false);


if(import.meta.env.VITE_MAINTENANCE == 'true' && window.location.pathname !== '/maintenance' && !document.cookie.match(/^(.*;)?\s*MAINTENANCE_BYPASS\s*=\s*[^;]+(.*)?$/)){
    // window.location.assign(window.location.origin + '/maintenance')
    maintenance.value = true;
}

onMounted(() => {
  if(!document.cookie.includes('profanToken')){
    const { setUser } = useUser();
    setUser(null);
  }
})


</script>

<template>
  <Suspense>
  <BaseLayoutVue v-if="!maintenance">
    <router-view v-slot="{ Component }">
      <keep-alive include="DashboardView,DashboardChartsView,DashboardUsersView,DashboardGlobalView, AirtableView">
        <component :is="Component" />
      </keep-alive>
    </router-view>

  </BaseLayoutVue>
  <template v-else>
    <Maintenance />
  </template>
</Suspense>
  
</template>

