import http from "../api-http-common";

class FeedBackService {
 
  post(data: any) {
    return http.post(`/feedback`,data);
  }


}

export default new FeedBackService();