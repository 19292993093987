<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { RouterLink, useRoute } from 'vue-router'
import router from '@/router'
import IframeContentComponent from '@/components/IframeContentComponent.vue'
import ChartsComponent from '@/components/ChartsComponent.vue';
import FeedbackComponent from '@/components/FeedbackComponent.vue'
import {
  Bars3Icon,
  ListBulletIcon,
  XMarkIcon,
  ArrowRightOnRectangleIcon,
  UserCircleIcon,
  QuestionMarkCircleIcon,
  Cog6ToothIcon,
  BellIcon,
  ChatBubbleOvalLeftEllipsisIcon
} from '@heroicons/vue/24/outline'
import { usePermissions } from '@/stores/utils'
import { useForumStore } from '@/stores/iframes'
import { useOwncloudStore } from '@/stores/iframes'
import { useUser } from '@/stores/user'
import { userManager } from '@/oidc/plugin'
import generatedTypes from '@/components/alert/types';
import AuthService from '@/services/AuthService';
import { storeToRefs } from 'pinia';

let user: any = await userManager.getUser()

// const flarumStore = useForumStore();

const { setUser } = useUser();
const { userStore, acceptedCharte } = storeToRefs(useUser());
// const haveCheckedCharte = ref(false);

// if (user) {
//   try {
//     AuthService.getProfile().then(
//       res => {
//         if (res.status == 200) {
//           useUser().setAcceptedCharte(res.data.hasAcceptedCharte);
//           useUser().setFilledInfos(res.data.hasFilledInfos);
//           // if filledInfos is false, route reload
//         }
//         haveCheckedCharte.value = true;
//       }
//     )
//   } catch (error) {
//     haveCheckedCharte.value = true;
//     console.error(error);
//   }
// }



const iframePage = ["Forum", "Cloud", "Vidéo"]
const perm: any = usePermissions;
const route = useRoute();
const navigation = ref();
const checkIfCharteIsOk = ref(user ? acceptedCharte || localStorage.getItem('charteAccepted') : true)

const feedback = ref()


function sendMail() {
  sidebarOpen.value = false
  feedback.value.open = true
}

// watch(acceptedCharte, () => {
//   checkIfCharteIsOk.value = acceptedCharte
//   console.log('checkIfCharteIsOk2', checkIfCharteIsOk.value)
// })


watch(
  () => route.name,
  async () => {
    user = await userManager.getUser()
    let userGroups = user?.profile?.groups;
    let userPerms = userGroups ? userGroups.map((el: string) => el = perm[el]).flat() : perm.disconnected

    navigation.value = router.getRoutes().filter(el => {
      return el.meta.allowNavigation && userPerms.includes(el.meta.permission)

    })
  }
)

// watch user
watch(
  () => user,
  async () => {
    setUser(user)

  }
)


const sidebarOpen = ref(false);
const hover = ref(false);

function triggerIframeEvent() {
  let iframeForum: HTMLIFrameElement | null = document.querySelector("iframe#forum")
  if (iframeForum) {
    iframeForum.contentWindow?.postMessage(
      "openclodesidebar",
      "https://forum.profan-transfert.limos.fr"
    );
  }
}

function RedirectToCloudActivity() {
  console.log('RedirectToCloudActivity')
  let url = 'https://cloud.profan-transfert.limos.fr/apps/activity';
  let iframeCloud: HTMLIFrameElement | null = document.querySelector('iframe#cloud');
  if (iframeCloud) {

    iframeCloud.contentWindow?.location.replace(url)
  } else {
    const store = useOwncloudStore();
    store.url = url
  }
  router.push({ name: 'Cloud' })
}

function RedirectToForumActivity() {

  let url = import.meta.env.VITE_FORUM_URL + 'u/' + user?.profile?.sub + '/activity';
  let iframeForum: HTMLIFrameElement | null = document.querySelector("iframe#forum");
  if (iframeForum) {
    iframeForum.contentWindow?.postMessage(
      "activityPage",
      "https://forum.profan-transfert.limos.fr"
    );
  } else {
    const store = useForumStore();
    store.url = url
  }
  router.push({ name: 'Forum' });
}


const staticSidebar = ref();
const main = ref();
onMounted(() => {
  staticSidebar.value.addEventListener("focusin", () => {
    if (!hover.value) {
      hover.value = true
    }
  });
  main.value.addEventListener("focusin", () => {
    if (hover.value) {
      hover.value = false
    }
  });
  // checkIfCharteIsOk.value = acceptedCharte
  // console.log('checkIfCharteIsOk2', checkIfCharteIsOk.value, acceptedCharte)
})

const alertMsg = ref([] as any[])
function addAlert(type: string, message: string) {
  alertMsg.value.push({ type: type, message: message });
}

function signout() {
  userManager.signoutRedirect()
}

function signin() {
  userManager.signinRedirect()
}



</script>

<template>
  <div>

    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog as="div" class="relative z-50 lg:hidden" @close="sidebarOpen = false">
        <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0"
          enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100"
          leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-900/80"></div>
        </TransitionChild>

        <div class="fixed inset-0 flex">
          <TransitionChild as="template" enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full" enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0"
            leave-to="-translate-x-full">
            <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
              <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0"
                enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
                <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
                  <button type="button" class="-m-2.5 p-2.5" @click="sidebarOpen = false">
                    <span class="sr-only">Fermer sidebar</span>
                    <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
              <!-- Sidebar component, swap this element with another sidebar if you like -->
              <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2">
                <div class="flex justify-center pt-3 w-full">
                  <div class="flex flex-nowrap gap-1">
                    <img class="h-full w-auto" src="/logoL.svg" alt="ProFan Transfert" />
                    <img class="h-full w-auto" src="/logoRoptimized.svg" alt="ProFan Transfert Chevron" />
                  </div>
                </div>
                <nav class="flex flex-1 flex-col">
                  <ul role="list" class="flex flex-1 flex-col gap-y-7">
                    <li>

                      <ul role="list" class="-mx-3 space-y-1 mt-0 px-2 overflow-scroll overflow-x-hidden relative">
                        <!-- eslint-disable-next-line vue/no-use-v-if-with-v-for -->
                        <li v-for="item in navigation" :key="String(item.name) + 'mobile_is_auth'">
                          <RouterLink :to="item.path" @click="sidebarOpen = false"
                            class=" hover:text-blue-600 hover:bg-gray-100 text-gray-400 relative transition-all group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                            :class="[!hover && 'w-full px-6']" active-class="!text-blue-600 !fill-blue-600 bg-gray-100">
                            <component :is="item.meta.navigationIcon"
                              class=" group-hover:text-blue-600 h-6 w-6 shrink-0 " aria-hidden="true" />
                            <span>{{ item.name }}</span>
                          </RouterLink>
                        </li>

                      </ul>
                    </li>
                    <!-- <li class="-mx-6 mt-auto">
                        <a href="#" class="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100">
                          <component :is="'ArrowRightOnRectangleIcon'" class="text-gray-400 group-hover:text-blue-600 h-6 w-6 shrink-0" aria-hidden="true" />
                          <span class="sr-only">Your profile</span>
                          <span aria-hidden="true">Tom Cook</span>
                        </a>
                      </li> -->
                  </ul>
                  <div>













                    <button v-if="user" @click="sendMail" aria-label="Bouton de demande d'aide au support"
                      class="h-12 flex text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100 py-2 pl-5 overflow-hidden gap-3 items-center group w-full cursor-pointer">
                      <component :is="QuestionMarkCircleIcon"
                        class="text-gray-400 group-hover:text-blue-600 h-6 w-6 shrink-0" aria-hidden="true" />
                      <span aria-hidden="true"
                        class=" whitespace-nowrap overflow-hidden text-gray-500 group-hover:text-blue-600">{{ user ? 'Feedback' : 'Support' }}</span>

                    </button>

                    <RouterLink v-if="user" to="/profile" @click="sidebarOpen = false"
                      class="h-12 flex text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100 py-2 pl-5 overflow-hidden gap-3 items-center group w-full">
                      <component :is="Cog6ToothIcon" class="text-gray-400 group-hover:text-blue-600 h-6 w-6 shrink-0"
                        aria-hidden="true" />

                      <span aria-hidden="true"
                        class=" whitespace-nowrap overflow-ellipsis overflow-hidden group-hover:text-blue-600"> {{
                          user?.profile?.name }}</span>

                    </RouterLink>
                    <button v-if="user" aria-label="Bouton de deconnection" @click="signout"
                      class="h-12 flex text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100 py-2 pl-5 overflow-hidden gap-3 items-center group w-full">
                      <component :is="ArrowRightOnRectangleIcon"
                        class="text-gray-400 group-hover:text-blue-600 h-6 w-6 shrink-0" aria-hidden="true" />
                      <span aria-hidden="true"
                        class=" whitespace-nowrap overflow-hidden text-gray-700 group-hover:text-blue-600">Se
                        déconnecter</span>

                    </button>

                    <button v-else @click="signin" aria-label="Bouton de connection"
                      class="h-12 flex text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100 py-2 pl-5 overflow-hidden gap-3 items-center group w-full">
                      <component :is="UserCircleIcon" class="text-gray-400 group-hover:text-blue-600 h-6 w-6 shrink-0"
                        aria-hidden="true" />
                      <span aria-hidden="true"
                        class=" whitespace-nowrap overflow-hidden text-gray-700 group-hover:text-blue-600">Se
                        connecter</span>

                    </button>















                  </div>
                </nav>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
    <!-- Static sidebar for desktop -->
    <div ref="staticSidebar" class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col transition-all"
      :class="[!hover && 'lg:!w-16 duration-300']" @mouseover="hover = true" @mouseleave="hover = false">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div
        class="h-screen flex grow flex-col overflow-y-auto overflow-x-hidden border-r border-gray-200 bg-white transition-all"
        :class="[hover && 'px-6']">
        <div class="flex justify-center w-full py-3">
          <!-- <img class="h-auto w-auto" src="/logoProfan.png" alt="ProFan Transfert" /> -->
          <div class="flex flex-nowrap gap-1 h-6 px-6" :class="[!hover && 'px-0']">
            <img class="h-full w-full transition-all duration-500 delay-50"
              :class="[!hover && '!w-0 !delay-0 !duration-0']" src="/logoL.svg" alt="ProFan Transfert" />
            <img class="h-full w-auto fill-blue-600" src="/logoRoptimized.svg" alt="ProFan Transfert Chevron" width="14"
              height="24" />
          </div>
        </div>
        <nav class="flex flex-col justify-between h-full max-h-[calc(100%-4rem)] gap-2">
          <ul role="list" class="-mx-3 space-y-1 mt-0 px-2 overflow-scroll overflow-x-hidden relative py-2">
            <!-- eslint-disable-next-line vue/no-use-v-if-with-v-for -->
            <li v-for="item in navigation" :key="String(item.name) + 'desk_is_auth'" class="grid grid-cols-6 gap-1">

              <template v-if="item.name == 'Cloud'">
                <RouterLink :to="item.path" :id="item.name" :ariaLabel="'Bouton de navigation vers ' + item.name"
                  class=" hover:text-blue-600 hover:bg-gray-100 text-gray-400 relative transition-all group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                  :class="[hover ? 'col-span-5' : 'w-full px-6 col-span-full']"
                  active-class="!text-blue-600 !fill-blue-600 bg-gray-100">
                  <component :is="item.meta.navigationIcon" class=" group-hover:text-blue-600 h-6 w-6 shrink-0 "
                    aria-hidden="true" />
                  <span v-if="hover" class=" whitespace-nowrap overflow-ellipsis overflow-hidden">{{ item.name }}</span>
                </RouterLink>
                <button v-if="hover" @click="RedirectToCloudActivity"
                  class="col-span-1 hover:text-blue-600 hover:bg-gray-100 text-gray-400 relative transition-all group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                  :class="[!hover && 'w-full px-6']" active-class="!text-blue-600 !fill-blue-600 bg-gray-100">
                  <component :is="BellIcon" class=" group-hover:text-blue-600 h-6 w-6 shrink-0 " aria-hidden="true" />
                </button>
              </template>
              <template v-else-if="item.name == 'Forum'">
                <RouterLink :to="item.path" :id="item.name" :ariaLabel="'Bouton de navigation vers ' + item.name"
                  class=" hover:text-blue-600 hover:bg-gray-100 text-gray-400 relative transition-all group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                  :class="[hover ? 'col-span-5' : 'w-full px-6 col-span-full']"
                  active-class="!text-blue-600 !fill-blue-600 bg-gray-100">
                  <component :is="item.meta.navigationIcon" class=" group-hover:text-blue-600 h-6 w-6 shrink-0 "
                    aria-hidden="true" />
                  <span v-if="hover" class=" whitespace-nowrap overflow-ellipsis overflow-hidden">{{ item.name }}</span>
                </RouterLink>
                <button v-if="hover" @click="RedirectToForumActivity"
                  class="col-span-1 hover:text-blue-600 hover:bg-gray-100 text-gray-400 relative transition-all group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                  :class="[!hover && 'w-full px-6']" active-class="!text-blue-600 !fill-blue-600 bg-gray-100">
                  <component :is="ChatBubbleOvalLeftEllipsisIcon" class=" group-hover:text-blue-600 h-6 w-6 shrink-0 "
                    aria-hidden="true" />
                </button>
              </template>
              <template v-else>
                <RouterLink :to="item.path" :id="item.name" :ariaLabel="'Bouton de navigation vers ' + item.name"
                  class="col-span-full hover:text-blue-600 hover:bg-gray-100 text-gray-400 relative transition-all group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                  :class="[!hover && 'w-full px-6']" active-class="!text-blue-600 !fill-blue-600 bg-gray-100">
                  <component :is="item.meta.navigationIcon" class=" group-hover:text-blue-600 h-6 w-6 shrink-0 "
                    aria-hidden="true" />
                  <span v-if="hover" class=" whitespace-nowrap overflow-ellipsis overflow-hidden">{{ item.name }}</span>
                </RouterLink>
              </template>
            </li>
            <!-- <li v-else v-for="item in navigation" :key="item.name+'desk_not_auth'">
                    <RouterLink v-if="!item.requiredAuth" :to="item.href" class=" hover:text-blue-600 hover:bg-gray-100 text-gray-400 relative transition-all group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold" :class="[!hover && 'w-full px-6']" active-class="!text-blue-600 !fill-blue-600 bg-gray-100" >
                      <component :is="item.icon" class=" group-hover:text-blue-600 h-6 w-6 shrink-0 " aria-hidden="true"/>
                      <span v-if="hover" class=" whitespace-nowrap overflow-ellipsis overflow-hidden">{{ item.name }}</span>
                      
                    </RouterLink>
                  </li>  -->
          </ul>

          <div>

            <button v-if="user" @click="sendMail" aria-label="Bouton de demande d'aide au support"
              class="h-12 flex text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100 py-2 pl-5 overflow-hidden gap-3 items-center group w-full cursor-pointer">
              <component :is="QuestionMarkCircleIcon" class="text-gray-400 group-hover:text-blue-600 h-6 w-6 shrink-0"
                aria-hidden="true" />
              <span v-if="hover" aria-hidden="true"
                class=" whitespace-nowrap overflow-hidden text-gray-500 group-hover:text-blue-600">
                {{ user ? 'Feedback' : 'Support' }}
              </span>

            </button>

            <RouterLink v-if="user" to="/profile"
              class="h-12 flex text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100 py-2 pl-5 overflow-hidden gap-3 items-center group w-full">
              <component :is="Cog6ToothIcon" class="text-gray-400 group-hover:text-blue-600 h-6 w-6 shrink-0"
                aria-hidden="true" />

              <span v-if="hover" aria-hidden="true"
                class=" whitespace-nowrap overflow-ellipsis overflow-hidden group-hover:text-blue-600"> {{
                  user?.profile?.name }}</span>

            </RouterLink>
            <button v-if="user" aria-label="Bouton de deconnection" @click="signout"
              class="h-12 flex text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100 py-2 pl-5 overflow-hidden gap-3 items-center group w-full">
              <component :is="ArrowRightOnRectangleIcon"
                class="text-gray-400 group-hover:text-blue-600 h-6 w-6 shrink-0" aria-hidden="true" />
              <span v-if="hover" aria-hidden="true"
                class=" whitespace-nowrap overflow-hidden text-gray-700 group-hover:text-blue-600">Se déconnecter</span>

            </button>

            <button v-else @click="signin" aria-label="Bouton de connection"
              class="h-12 flex text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100 py-2 pl-5 overflow-hidden gap-3 items-center group w-full">
              <component :is="UserCircleIcon" class="text-gray-400 group-hover:text-blue-600 h-6 w-6 shrink-0"
                aria-hidden="true" />
              <span v-if="hover" aria-hidden="true"
                class=" whitespace-nowrap overflow-hidden text-gray-700 group-hover:text-blue-600">Se connecter</span>

            </button>

          </div>
        </nav>
      </div>
    </div>

    <div class=" top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden">
      <button type="button" class="-m-2.5 p-2.5 text-gray-700 lg:hidden" @click="sidebarOpen = true">
        <span class="sr-only">Open sidebar</span>
        <Bars3Icon class="h-6 w-6" aria-hidden="true" />
      </button>
      <div class="flex-1 text-sm font-semibold leading-6 text-gray-900">{{ route.name }}</div>
      <a href="#" v-if="user">
        <span class="sr-only">Your profile</span>
        <img v-if="user?.profile?.images" class="h-8 w-8 rounded-full bg-gray-50 border-spacing-0"
          src="https://storage.letudiant.fr/mediatheque/educpros/7/1/586071-monteil-jean-marc-nb-160x160.jpg" alt="" />
        <div v-else
          class="h-8 w-8 min-w-[2rem] rounded-full border-spacing-0 grid place-items-center bg-blue-500 text-white cursor-default">
          {{ user?.profile?.name?.slice(0, 1) }}</div>
      </a>
      <button v-if="user && route.name == 'Forum'" type="button" class="-m-2.5 p-2.5 text-gray-700 lg:hidden"
        @click="triggerIframeEvent()">
        <span class="sr-only">Open sidebar</span>
        <ListBulletIcon class="h-6 w-6" aria-hidden="true" />
      </button>
    </div>

    <!-- :class="[iframePage.includes(String(route.name)) ? 'h-[calc(100vh_-_4rem)]' : ' min-h-screen']" -->
    <main ref="main" class="h-[calc(100vh_-_4rem)] lg:min-h-screen lg:pl-16 w-full relative scroll-smooth">
      <div id="alerts" class="lg:pl-14 w-full fixed top-14 lg:top-0 left-1/2 -translate-x-1/2 flex flex-col z-20 h-min">
        <component v-for="(value, key) in alertMsg" :is="generatedTypes.get(value.type)" :message="value.message"
          :key="key" class=" animate-notification" />

      </div>
      <slot></slot>
      <IframeContentComponent v-if="user" />
      <!-- <iframe v-else class="w-0 h-0 overflow-hidden"
              :src="flarumStore.url+'#logout'" > 
           </iframe>  -->
      <!-- <LoaderComponent v-if="GlobalAppLoading === true"/> -->

    </main>
  </div>

  <ChartsComponent v-if="acceptedCharte == false" />
  <FeedbackComponent  v-if="user" :add-alert-parent="addAlert" ref="feedback" :connectedUser="user" />
</template>

@/stores/user