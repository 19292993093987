<script setup lang="ts">

import GeneratedInputText from '@/components/inputs/generated/GeneratedInputText.vue';
import GeneratedListBox from '@/components/inputs/generated/GeneratedListBox.vue'
import GeneratedComboBox from '@/components/inputs/generated/GeneratedComboBox.vue'
import generatedTypes from '@/components/alert/types';

import PersonnalAccountComponent from '@/components/profile/PersonnalAccountComponent.vue'
import PasswordAccountComponent from '@/components/profile/PasswordAccountComponent.vue'
import NotificationAccountComponent from '@/components/profile/NotificationAccountComponent.vue'
const secondaryNavigation = [
  { name: 'Informations Personnel', href: '#infoperso' },
  { name: 'Mot de passe', href: '#motdepasse' },
  { name: 'Notifications', href: '#notification' }
]


</script>
<template>


<h1 class="sr-only">Account Settings</h1>

        <header class="border-b border-black/5 sticky top-0 bg-white z-10">
          <!-- Secondary navigation -->
          <nav class="flex overflow-x-auto py-4">
            <ul role="list" class="flex min-w-full flex-none gap-x-6 px-4 text-sm font-semibold leading-6 text-gray-400 sm:px-6 lg:px-8">
              <li v-for="item in secondaryNavigation" :key="item.name">
                <a :href="item.href" class="active:text-blue-500 focus:text-blue-500">{{ item.name }}</a>
              </li>
            </ul>
          </nav>
        </header>

        <!-- Settings forms -->
        <div class="divide-y divide-black/5 flex flex-col items-center w-full">
          
          <PersonnalAccountComponent id="infoperso"/>
          <PasswordAccountComponent id="motdepasse"/>
          <NotificationAccountComponent id="notification"/>
          

          
        </div>


</template>