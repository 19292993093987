import http from "../api-http-common";

class CharteService {
  charteGet() {
    return http.get("/charte/");
  }

  chartePost() {
    return http.post("/charte/");
  }

}

export default new CharteService();
