<script setup lang="ts">

import {userManager} from "../plugin";
import {useRouter} from "vue-router";

const router = useRouter();

userManager.signinRedirectCallback().then(() => {
  router.push("/");
})
</script>