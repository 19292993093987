<script setup lang="ts">

const props = defineProps<{
  name: string,
  id: string,
  label: string,
  placeholder?: string,
  max?: number,
  min?: number,
  defaultValues?: any,
  type?: string,
  required?: boolean
}>()
defineExpose({
   props
});
</script>

<template>
  
<div>
  <label :for="String(props.id)" class="block text-sm font-medium leading-6 text-gray-900"><b v-if="props.required" class="text-red-600">*</b><b>{{ props.name }}</b> {{ props.label }}</label>
  <div class="relative mt-2 rounded-md shadow-sm">
    <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center px-3">
      <span class="text-gray-500 sm:text-sm">0-9</span>
    </div>
    <input :value="props.defaultValues" :placeholder="props.placeholder" :name="props.name" :id="String(props.id)" :type="props.type ?? 'number'" :min="props.min" :max="props.max" class="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6" />
    
  </div>
</div>

</template>