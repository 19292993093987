
import { InMemoryWebStorage, UserManager } from 'oidc-client-ts'
import {WebStorageStateStore} from "oidc-client-ts";
import { routeManager } from '@/router/oidcRouteMgr'


declare global {
    interface Date {
        addHours(h: number): Date;
    }
}

Date.prototype.addHours = function (h) {
    this.setTime(this.getTime() + (h * 60 * 60 * 1000));
    return this;
}


const oidcLocalStorageKey = "oidc.user:"+import.meta.env.VITE_SSO_REALM_URL+":"+import.meta.env.VITE_SSO_CLIENT_ID

const userManager = new UserManager({
    authority:      import.meta.env.VITE_SSO_REALM_URL,
    client_id:      import.meta.env.VITE_SSO_CLIENT_ID,
    client_secret:  import.meta.env.VITE_SSO_CLIENT_SECRET,

    monitorSession: true,

    redirect_uri: import.meta.env.VITE_ROOT_URL+"callback",
    post_logout_redirect_uri: import.meta.env.VITE_ROOT_URL+"logout",

    response_type: "code",
    scope: "openid profile email",
    userStore: new WebStorageStateStore({store: window.localStorage}),
    // userStore: new WebStorageStateStore({store: new InMemoryWebStorage()}),
    
    automaticSilentRenew: true,
    includeIdTokenInSilentRenew: true,
    loadUserInfo: true,
})

userManager.events.addUserLoaded(async (user) => {
    document.cookie = `profanToken=${user.access_token}; domain=profan-transfert.limos.fr; value=${JSON.stringify(user)};expires=${((new Date()).addHours(1)).toUTCString()};path=/;SameSite=None;Secure;`
        
}
)

userManager.events.addUserUnloaded(() => {
    document.cookie = 'profanToken=; domain=profan-transfert.limos.fr;expires=Thu, 01 Jan 1970 00:00:00 GMT'
}
)


function jsonParse(v:any){try{return JSON.parse(v)}catch(e){return null}}
// used in instance thats created before pinia store
function getUser() {
    const user = jsonParse(localStorage.getItem(oidcLocalStorageKey))
    return user
}


const oidcPlugin = {
    install: (_app: any, option: any) => {
        const {router} = option;
        const getLocalUser = ()=>getUser()
        routeManager(router,userManager,getLocalUser)
        
    }
}


export {oidcPlugin, userManager, getUser}
