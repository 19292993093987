<script setup lang="ts">
import { ChatBubbleLeftRightIcon, CloudIcon, UserCircleIcon } from '@heroicons/vue/24/outline';

import HomeCardComponent from '@/components/HomeCardComponent.vue';
import { ref} from 'vue';

import { userManager } from '@/oidc/plugin'

let user: any = await userManager.getUser()

const ref_def_profan = ref();

function highlight(ref:any){
  ref.classList.add('bg-slate-100');
  setTimeout(()=>{ref.classList.remove('bg-slate-100')},1500)
}

function connect(){
  userManager.signinRedirect()
}
</script>

<template>

    <div class="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0 w-full">

    <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
      <div class="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
        <div class="lg:pr-4">
          <div class="lg:max-w-lg">
            <img src="/ProFAN.svg" alt="" class="lg:hidden w-52 mb-10" width="208" height="59">
            
            <h1 class="mt-2 text-2xl  sm:text-3xl  font-bold tracking-tight text-[#4b559c]">Bienvenue sur la plateforme  <a href="#def-profan" class="underline" @click="highlight(ref_def_profan)">ProFAN Transfert</a>&nbsp;!</h1>
            <p class="mt-6 text-lg leading-8 text-gray-700">
                Cette plateforme collaborative est destinée au recueil d’informations utiles, au suivi et à l’évaluation continue du dispositif et de son efficacité dans un espace de travail partagé avec les chercheurs et entre tous les participants tout au long du déroulement de l’opération.
            </p>
          </div>
        </div>
      </div>
      <div class="hidden lg:inline-block -ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
        <div class="container flex flex-col justify-center gap-2 w-3/4  lg:w-[800px] flex-wrap">
            <img src="/ProFAN.svg" alt="" class="hidden lg:inline-block w-52 mb-10" width="208" height="59">
            <div v-if="user" class="container flex flex-col justify-center gap-2 w-3/4  lg:w-[800px] flex-wrap">
              <p class="text-base font-semibold leading-7 text-blue-600">Applications principales</p>
                <!-- <HomeCardComponent 
                :title="'Forum'" 
                :p="'Poser des questions, discuter, échanger et partager des idées et des propositions.'" 
                :href="'/forum'"
                :component="ChatBubbleLeftRightIcon"
                class=" min-h-[150px] max-w-[90vw]"
                />  -->


                <HomeCardComponent 
                :title="'Cloud'" 
                :p="'Consulter des ressources, enregistrer, éditer et partager des documents.'" 
                :href="'/cloud'"
                :component="CloudIcon"
                class=" min-h-[150px] max-w-[90vw]"
                />
            </div>
            <div v-else class="container flex flex-col justify-center gap-2 w-3/4  lg:w-[800px] flex-wrap">
              <button @click="connect" aria-label="Bouton de connection" class="flex flex-col w-max border-2 border-gray-200 rounded-md p-4 text-gray-500 group hover:border-blue-200 cursor-pointer">
                <div class="flex flex-row gap-2 text-gray-700 group-hover:text-blue-500">
                    <UserCircleIcon class="w-7 h-7" aria-hidden="true" />
                    <h2 class="text-xl font-bold">Se connecter</h2>
                </div>
              </button>
            </div>
           
        </div>
    </div>
      <div class="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
        <div class="lg:pr-4">
          <div class="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
            <p><b>ProFAN Transfert</b> est un dispositif national financé par le <b>SGPI</b> dans le cadre du <b>PIA France 2030</b>. Conçu par la Mission Monteil qui en assure le pilotage, en relation avec la DGESCO, il est opéré par l’Agence nationale pour la recherche et s’inscrit dans la mesure 12 de la réforme du lycée professionnel, « Permettre une nouvelle approche pédagogique autour du projet de l’élève ».
            </p>
            <br>
            <p>Outre les deux applications principales : le Forum et le Cloud, des formulaires peuvent vous être proposés ponctuellement et pour une durée limitée, afin de documenter l’avancement du projet dans ses différentes étapes.</p>
            <br>
            <p id="def-profan" ref="ref_def_profan" class="mt-8 transition-colors duration-700 ease-in-out">* ProFAN Transfert est conçue et administrée par les chercheurs du <a href="https://limos.fr/" class="underline text-blue-700">LIMOS</a>, laboratoire CNRS et UCA, membre du consortium ProFAN.</p>
            <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">Un problème ?</h2>
            <p class="mt-6">L'équipe du LIMOS se tient à votre écoute en cas de problème. Vous pouvez nous contacter en cliquant sur le bouton "Feedback" dans la barre latérale gauche (en y déplaçant votre curseur) ou par mail <u>profan-transfert@limos.fr</u> .</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>