<script setup lang="ts">
import { ref } from 'vue';



const props = defineProps<{
  name: string,
  id: string,
  label: string,
  placeholder?: string;
  values?: string;
  rows?: number;
  defaultValues?: any,
  textareaClass?: string,
  required?: boolean,
  disabled?: boolean,
}>()
// eslint-disable-next-line vue/no-setup-props-destructure

const actualValue = ref();
  defineExpose({
    actualValue,
    props
  });

  function resize(event: Event) {
    const target = event.target as HTMLTextAreaElement;
    target.style.height = 'auto';
    target.style.height = target.scrollHeight + 'px';
  }

</script>

<template>
<div>
  <label for="about" class="block text-sm font-medium leading-6 text-gray-900"><b v-if="props.required" class="text-red-600">*</b><b>{{ props.name }}</b> {{ props.label }}</label>
  <div class="mt-2">
      <textarea :disabled="disabled" @input="resize" ref="actualValue" :value="props.defaultValues ?? props.values" :placeholder="props.placeholder" :name="props.name" :id="props.id" :rows="props.rows ?? 3" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 disabled:bg-gray-100" :class="textareaClass">
      </textarea>
  </div>
</div>

</template>