
<script setup lang="ts">
import { useForumStore, useOwncloudStore, useUtilsStore } from '@/stores/iframes';
import { storeToRefs } from 'pinia';
import { onMounted } from 'vue';

const owncloudStore = useOwncloudStore();

const flarumStore = useForumStore();

</script>

<template>

    <iframe :name="'cloud-'+Date.now()" @load="owncloudStore.loaded = true" :ref="storeToRefs(owncloudStore).iframe"  id="cloud" v-if="owncloudStore.activeIframe && !owncloudStore.errLoading" v-show="owncloudStore.visible" class="w-full min-h-full overflow-hidden"
        :src="owncloudStore.url"
            frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
    </iframe>

    <iframe :name="'forum-'+Date.now()" @load="flarumStore.loaded = true" :ref="storeToRefs(flarumStore).iframe" id="forum" v-if="flarumStore.activeIframe && !flarumStore.errLoading" v-show="flarumStore.visible" class="w-full min-h-full overflow-hidden"
        :src="flarumStore.url" 
            frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
    </iframe> 


</template>