<script setup lang="ts">
import GeneratedInputText from '@/components/inputs/generated/GeneratedInputText.vue';
import GeneratedListBox from '@/components/inputs/generated/GeneratedListBox.vue'
import GeneratedComboBox from '@/components/inputs/generated/GeneratedComboBox.vue'
import generatedTypes from '@/components/alert/types';
import { EyeSlashIcon as solidEyeSlashIcon } from '@heroicons/vue/20/solid';
import { EyeSlashIcon as oulineEyeSlashIcon, ArrowPathIcon } from '@heroicons/vue/24/outline';
import { ref } from 'vue';
import AuthService from '@/services/AuthService';
import { useUser } from '@/stores/user';

const { getUser } = useUser();

const pwd1 = ref();
const pwd2 = ref();
const pwdNow = ref();
const pwd1Hide = ref(true);
const pwd2Hide = ref(true);
const pwdNowHide = ref(true);

const validator12char = ref(false);
const regex12char = new RegExp('.{12,32}');

const validatorMajchar = ref(false);
const regexMajchar = new RegExp('[A-Z]');

const validatorMinchar = ref(false);
const regexMinchar = new RegExp('[a-z]');

const validatorNumber = ref(false);
const regexNumber = new RegExp('[0-9]');

const validatorSpecchar = ref(false);
const regexSpecchar = new RegExp('\\W+');

const progressValue = ref(0);

function checkPwdValidity(e: Event) {
    const target = e.target as HTMLInputElement;
    validator12char.value = regex12char.test(target.value);
    validatorMajchar.value = regexMajchar.test(target.value);
    validatorMinchar.value = regexMinchar.test(target.value);
    validatorNumber.value = regexNumber.test(target.value);
    validatorSpecchar.value = regexSpecchar.test(target.value);

    progressValue.value = (Number(validator12char.value) + Number(validatorMajchar.value) + Number(validatorMinchar.value) + Number(validatorNumber.value) + Number(validatorSpecchar.value));

}
const alertMsg = ref([] as any[])

function addAlert(type: string, message: string) {
    alertMsg.value.push({ type: type, message: message });

}

async function onSubmit(e:Event) {

    load.value = true;
    if(progressValue.value != 5){
        addAlert('ErrorComponent','Le mot de passe doit respecter les critères de sécurité !')
        load.value = false;
        return;
    }

    if(!(pwd1.value.value == pwd2.value.value)){
        addAlert('ErrorComponent','Les mots de passe ne correspondent pas !')
        load.value = false;

        return;
    }

    if(pwd1.value.value == pwdNow.value.value){
        addAlert('ErrorComponent','Le nouveau mot de passe doit être différent de l\'ancien !')
        load.value = false;

        return;
    }


    let res;
    try {
      res = await AuthService.pwdChangePost(
            {
                oldPassword: pwdNow.value.value,
                newPassword: pwd1.value.value,
            }
        )

    } catch (error:any) {
        addAlert('ErrorComponent',`Une erreur est survenue ! ${error.response.data.error ?? ''}`)
        load.value = false; 
      return;
    }

    if(res.status == 201){
        addAlert('SuccessComponent','Changement de mot de passe réussi !')
        pwd1.value.value = '';
        pwd2.value.value = '';
        pwdNow.value.value = '';
        load.value = false;

        return;
    }
   
    addAlert('ErrorComponent',`Erreur ${res.status}: Un problème est survenue, veuillez réessayer ultérieurement ou contacter le support !`)
    load.value = false;
}

const load = ref(false);

</script>



<template>
  <Teleport to="#alerts">
    <component v-for="(value, key) in alertMsg" :is="generatedTypes.get(value.type)" :message="value.message" :key="key"
      class=" animate-notification" />
  </Teleport>
    <div class="grid w-full md:max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
        <div class="w-full lg:w-80">
            <h2 class="text-base font-semibold leading-7 text-black">Changement de mot de passe</h2>
            <p class="mt-1 text-sm leading-6 text-gray-400">Utilisez un mot de passe sécurisé différent pour chaque application.</p>
        </div>

        <form class="md:col-span-2 relative" @submit.prevent="onSubmit">
            <input type="email" class="invisible h-0 w-0 absolute" :value="getUser().profile.email"/>
            <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">

                <div class="col-span-full">
                    <label for="pwdNow" class="block text-sm font-medium leading-6 text-gray-900">Mot de passe
                        actuel:</label>
                    <div class="relative my-2 rounded-md shadow-sm">
                        <div class=" absolute inset-y-0 left-0 flex items-center pl-2">
                            <button type="button" @click="pwdNowHide = !pwdNowHide; pwdNow.focus()">
                                <solidEyeSlashIcon v-if="pwdNowHide"
                                    class="cursor-pointer h-7 w-7 p-1 text-gray-800 hover:bg-gray-100 hover:text-blue-500 rounded-full"
                                    aria-hidden="true" />
                                <oulineEyeSlashIcon v-else
                                    class="cursor-pointer h-7 w-7 p-1 text-gray-800 hover:bg-gray-100 hover:text-blue-500 rounded-full"
                                    aria-hidden="true" />

                            </button>
                        </div>
                        <input ref="pwdNow" @blur="pwdNowHide = true" placeholder="Saisir mot de passe" name="pwdNow"
                            id="pwdNow" :type="pwdNowHide ? 'password' : 'text'"
                            pattern="(?=^.{12,32}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$" required
                            class="block w-full rounded-md border-0 py-1.5 pl-10 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6" />

                    </div>
                </div>
                <div class="col-span-full">

                    <label for="pwd1" class="block text-sm font-medium leading-6 text-gray-900">Nouveau mot de passe
                        :</label>
                    <div class="relative mt-2 rounded-md shadow-sm">
                        <div class=" absolute inset-y-0 left-0 flex items-center pl-2">
                            <button type="button" @click="pwd1Hide = !pwd1Hide; pwd1.focus()">
                                <solidEyeSlashIcon v-if="pwd1Hide"
                                    class="cursor-pointer h-7 w-7 p-1 text-gray-800 hover:bg-gray-100 hover:text-blue-500 rounded-full"
                                    aria-hidden="true" />
                                <oulineEyeSlashIcon v-else
                                    class="cursor-pointer h-7 w-7 p-1 text-gray-800 hover:bg-gray-100 hover:text-blue-500 rounded-full"
                                    aria-hidden="true" />

                            </button>
                        </div>
                        <input @input="checkPwdValidity" ref="pwd1" @blur="pwd1Hide = true"
                            placeholder="Saisir mot de passe" name="pwd1" id="pwd1"
                            :type="pwd1Hide ? 'password' : 'text'"
                            pattern="(?=^.{12,32}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$" required
                            class="block w-full rounded-md border-0 py-1.5 pl-10 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6" />

                    </div>
                    <progress class="bg-gray-200 h-1.5 rounded-full w-full " id="file" max="5"
                        :value="progressValue"></progress>
                    <div class="flex w-full flex-col text-xs gap-1">
                        <span> <input :checked="validator12char" type="checkbox"
                                class="rounded-full h-3 w-3 bg-gray-300 border-none align-middle" disabled> 12
                            caractères
                            minimum </span>
                        <span> <input :checked="validatorMinchar" type="checkbox"
                                class="rounded-full h-3 w-3 bg-gray-300 border-none align-middle" disabled> Une
                            minuscule
                            minimum </span>
                        <span> <input :checked="validatorMajchar" type="checkbox"
                                class="rounded-full h-3 w-3 bg-gray-300 border-none align-middle" disabled> Une
                            majuscule
                            minimum </span>
                        <span> <input :checked="validatorNumber" type="checkbox"
                                class="rounded-full h-3 w-3 bg-gray-300 border-none align-middle" disabled> Un chiffre
                            minimum
                        </span>
                        <span> <input :checked="validatorSpecchar" type="checkbox"
                                class="rounded-full h-3 w-3 bg-gray-300 border-none align-middle" disabled> Un caractère
                            spécial
                            minimum : <em class=" bg-gray-200 px-1 text-gray-700"># * ? ! & =</em> </span>
                    </div>
                </div>
                <div class="col-span-full">
                    <label for="pwd2" class="block text-sm font-medium leading-6 text-gray-900">Confirmer mot de passe
                        :</label>
                    <div class="relative mt-2 rounded-md shadow-sm">
                        <div class=" absolute inset-y-0 left-0 flex items-center pl-2">
                            <button type="button" @click="pwd2Hide = !pwd2Hide; pwd2.focus()">
                                <solidEyeSlashIcon v-if="pwd2Hide"
                                    class="cursor-pointer h-7 w-7 p-1 text-gray-800 hover:bg-gray-100 hover:text-blue-500 rounded-full"
                                    aria-hidden="true" />
                                <oulineEyeSlashIcon v-else
                                    class="cursor-pointer h-7 w-7 p-1 text-gray-800 hover:bg-gray-100 hover:text-blue-500 rounded-full"
                                    aria-hidden="true" />

                            </button>
                        </div>
                        <input required ref="pwd2" @blur="pwd2Hide = true" placeholder="Saisir mot de passe" name="pwd2"
                            id="pwd2" :type="pwd2Hide ? 'password' : 'text'"
                            class="block w-full rounded-md border-0 py-1.5 pl-10 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6" />
                    </div>
                </div>
                

            </div>

            <div class="mt-8 flex">
                <button type="submit"
                    class="max-w-full rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                    Enregistrer
                    <ArrowPathIcon v-if="load" class="h-5 w-5 inline-block animate-spin " aria-hidden="true" />
                </button>
            </div>
        </form>
    </div>

</template>