<script setup lang="ts">
import GeneratedInputText from '@/components/inputs/generated/GeneratedInputText.vue';
import GeneratedListBox from '@/components/inputs/generated/GeneratedListBox.vue'
import GeneratedInputNumber from '../inputs/generated/GeneratedInputNumber.vue';
import { useUser } from '@/stores/user';
import generatedTypes from '@/components/alert/types';
import { ArrowPathIcon } from '@heroicons/vue/24/outline';

import InspectorPersonnalAccountComponent from '@/components/profile/InspectorPersonnalAccountComponent.vue';
import ChefetablPersonnalAccountComponent from '@/components/profile/ChefetablPersonnalAccountComponent.vue';
import FormatorPersonnalAccountComponent from './FormatorPersonnalAccountComponent.vue';

import FormsInfoService from '@/services/FormsInfoService';
import { onMounted, ref } from 'vue';
import { useUtilsForm } from '@/stores/utils';


const userProfile = ref();
const loadedValues = ref(false);
const load = ref(false);

onMounted(() => {
  FormsInfoService.get().then((response) => {
    userProfile.value = response.data;
    loadedValues.value = true;
  }).catch((error) => {
    console.log(error)
  })
  userProfile.value = true;

});


const { getUser } = useUser();

const gender = {
  name: "genre",
  id: "idgenger",
  label: "Genre :",
  values: [
    {
      name: "Homme",
      id: "homme"
    },
    {
      name: "Femme",
      id: "femme"
    },
    {
      name: "Autre",
      id: "autre"
    }
  ],
  noBoldName: true
}


const alertMsg = ref([] as any[])

function addAlert(type: string, message: string) {
  alertMsg.value.push({ type: type, message: message });

}

async function onSubmit(e: any) {
  load.value = true;
  const inputs = useUtilsForm().filterInputsForm(e);
  // console.log('inputs', inputs)
  const profileCopy = { ...userProfile.value };
  // foreach value in inputs, update profileCopy, ingnoring [ given_name family_name email academie ]
  for (const key in inputs) {
    if (key != 'given_name' && key != 'family_name' && key != 'email' && key != 'academie') {
      profileCopy[key] = inputs[key];
    }
  }
  

  const subJson = { data: inputs };
  let res;
  try {
    res = await FormsInfoService.post(subJson, 'user')
  } catch (error: any) {
    addAlert('ErrorComponent', `Une erreur est survenue ! ${error.response.data.error ?? ''}`)
    load.value = false;
    return;
  }

  if (res.status == 201) {
    addAlert('SuccessComponent', 'Modification réussie !')
    load.value = false;
    return
  }

  addAlert('ErrorComponent', 'Une erreur est survenue, veuillez réessayer ultérieurement ou contacter le support !')
  load.value = false;
}

</script>



<template>
  <Teleport to="#alerts">
    <component v-for="(value, key) in alertMsg" :is="generatedTypes.get(value.type)" :message="value.message" :key="key"
      class=" animate-notification" />
  </Teleport>
  <div class="grid w-full md:max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">

    <div class="w-full lg:w-80">
      <h2 class="text-base font-semibold leading-7 text-black">Informations personnelles</h2>
      <p class="mt-1 text-sm leading-6 text-gray-400">Vous pouvez modifier les informations qui ne sont pas grisées.</p>
    </div>

    <form class="md:col-span-2" v-if="loadedValues == true" @submit.prevent="onSubmit">


      <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
        <GeneratedInputText class="sm:col-span-3" label="Prénom" id="first-name" name="" :disabled="true"
          :default-values="userProfile.given_name" />
        <GeneratedInputText class="sm:col-span-3" label="Nom" id="last-name" name="" :disabled="true"
          :default-values="userProfile.family_name" />
        <GeneratedInputText class="sm:col-span-3" symbol="@" label="Email" id="email" name="" :disabled="true"
          :default-values="getUser().profile.email" />
        <GeneratedInputText class="sm:col-span-3" label="Académie" id="academie" name="" :disabled="true"
          :default-values="userProfile.academie" />
        <GeneratedInputNumber class="sm:col-span-3" label="Age" id="old" name="" placeholder="Age"
          :default-values="userProfile.old" />
        <GeneratedListBox class="sm:col-span-3" v-bind="gender"
          :default-values="userProfile.idgenger" />
        <GeneratedInputNumber class="col-span-full" label="Année de 1ère affection" id="year" name="" pattern="[0-9]{4}"
          :min="new Date().getFullYear() - 100" :max="new Date().getFullYear()" :default-values="userProfile.year"
          :placeholder="String(new Date().getFullYear())" />
        <InspectorPersonnalAccountComponent v-if="getUser().profile.groups.includes('inspecteur')" :user-profile="userProfile" />
        <ChefetablPersonnalAccountComponent v-if="getUser().profile.groups.includes('chefetablissement')" :user-profile="userProfile" />
        <FormatorPersonnalAccountComponent
          v-if="getUser().profile.groups.includes('formateur') || getUser().profile.groups.includes('forme')" :user-profile="userProfile"/>


      </div>

      <div class="mt-8 flex">
        <button
          class="max-w-full rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
          <span v-if="!load">Enregistrer</span>
          <template v-else>
            <span>Enregistrement</span>
            <ArrowPathIcon class="h-5 w-5 ml-2 inline-block animate-spin " aria-hidden="true" />
          </template>
        </button>
      </div>
    </form>

  </div>

</template>