import http from "../api-http-common";

class AirtableService {
 

  // -------------------------
  // Airtable
  // -------------------------

  getSearch(query: string) {
    return http.get(`/airtable/search?${query}`);
  }

  create(data: any = {}) {
    return http.post("/airtable", data);
  }

  get(id: string | string[]) {
    return http.get(`/airtable/${id}`);
  }

  getDrafts() {
    return http.get(`/airtable/me/draft`);
  }

  getPublisheds() {
    return http.get(`/airtable/me/published`);
  }

  getUsers() {
    return http.get(`/airtable/users`);
  }

  getAll() {
    return http.get("/airtable");
  }

  clone(id: string) {
    return http.post(`/airtable/${id}/clone`);
  }

  update(id: string, data: any) {
    return http.put(`/airtable/${id}`, data);
  }

  delete(id: string) {
    return http.delete(`/airtable/${id}`);
  }

  // -------------------------
  // Comments
  // -------------------------

  getComments(id: string) {
    return http.get(`/airtable/${id}/comments`);
  }

  createComment(id: string, data: any) {
    return http.post(`/airtable/${id}/comments`, data);
  }

  deleteComment(id: string, commentId: string) {
    return http.delete(`/airtable/${id}/comments/${commentId}`);
  }

  updateComment(id: string, commentId: string, data: any) {
    return http.put(`/airtable/${id}/comments/${commentId}`, data);
  }

  // -------------------------
  // Uploads
  // -------------------------

  getUpload(id: string, uploadId: string) {
    return http.get(`/airtable/${id}/uploads/${uploadId}`);
  }

  createUpload(id: string, data: any) {
    return http.post(`/airtable/${id}/uploads`, data);
  }

  deleteUpload(id: string, uploadId: string) {
    return http.delete(`/airtable/${id}/uploads/${uploadId}`);
  }

  updateUpload(id: string, uploadId: string, data: any) {
    return http.put(`/airtable/${id}/uploads/${uploadId}`, data);
  }

  downloadUpload(id: string, uploadId: string) {
    return http.get(`/airtable/${id}/uploads/${uploadId}/download`, {
      responseType: "blob",
    });
  }

  // -------------------------
  // Feedbacks
  // -------------------------

  getFeedback(id: string) {
    return http.get(`/airtable/${id}/feedbacks`);
  }

  createFeedback(id: string, data: any) {
    return http.post(`/airtable/${id}/feedbacks`, data);
  }

  deleteFeedback(id: string, feedbackId: string) {
    return http.delete(`/airtable/${id}/feedbacks/${feedbackId}`);
  }

  updateFeedback(id: string, feedbackId: string, data: any) {
    return http.put(`/airtable/${id}/feedbacks/${feedbackId}`, data);
  }


  // -------------------------
  // Tracking
  // -------------------------

  postTracking(data: any) {
    return http.post(`/tracking/airtable`, data);
  }







}

export default new AirtableService();