<template>
    <main class="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div class="text-center">
        <p class="text-base font-semibold text-blue-600">Erreur 404</p>
        <h1 class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Page non trouvée </h1>
        <p class="mt-6 text-base leading-7 text-gray-600">Désolé, la ressource demandée n'a pas été trouvée ! <br>
        Vous pouvez essayer de recharger la page ou de contacter le support.
        </p>
        <div class="mt-10 flex items-center justify-center gap-x-6">
          <a href="/" class="rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">Retourner à l'accueil</a>
          <a href="mailto:profan-transfert@limos.fr" class="text-sm font-semibold text-gray-900">Contacter le support <span aria-hidden="true">&rarr;</span></a>
        </div>
      </div>
    </main>
  </template>
  <script setup lang="ts">
  console.log('404')
  </script>