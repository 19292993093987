
<script setup lang="ts">
import { ref } from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline'
import CharteService from '@/services/CharteService';

const open = ref(true)
const checkedCharte = ref(false)
const inputCharte = ref()

async function updateCharte(){

  let res;
  try {
        res = await CharteService.chartePost()
    } catch (error:any) {
        res = error.response
        return;
    }

  if(res.status == 200){
    open.value = false;
    localStorage.setItem('charteAccepted','true')
  }


}


</script>

<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-50">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6 gap-5 flex flex-wrap flex-col">
                <DialogTitle as="h1" class="text-lg font-semibold leading-6 text-gray-900 py-5">CHARTE DE BON USAGE DE LA PLATEFORME ProFAN-Transfert
                </DialogTitle>

                <p class="mt-1 text-gray-600">

                La présente charte a pour objet de définir les conditions d’accès et les règles d'utilisation de la plateforme ProFAN Transfert mise à la disposition des utilisateurs par le laboratoire d'informatique, de modélisation et d'optimisation des systèmes, Unité mixte de recherche UMR 6158, membre du consortium ProFAN-Transfert.
            </p><p class="mt-1 text-gray-600">
                ProFAN Transfert est un dispositif national financé par le SGPI dans le cadre du PIA France 2030. Conçu par la Mission Monteil qui en assure le pilotage, en relation avec la DGESCO, il est opéré par l’Agence nationale pour la recherche et s’inscrit dans la mesure 12 de la réforme du lycée professionnel, « Permettre une nouvelle approche pédagogique autour du projet de l’élève ». 
            </p><p class="mt-1 text-gray-600">
                La plateforme est destinée à la diffusion de ressources et au recueil d’informations utiles au suivi et à l’évaluation continue du dispositif ProFAN-Transfert et de son efficacité. Elle est un espace de travail partagé avec les chercheurs membres du consortium et entre tous les participants, professeurs, formateurs, membres des corps d’inspection, ainsi réunis en réseau, tout au long du déroulement de l’opération. 
            </p><p class="mt-1 text-gray-600">
                Cette plateforme est mise à la disposition des utilisateurs à des fins de culture, de formation, d’enseignement, de recherche et de diffusion d’informations scientifiques et pédagogiques. 
            </p><p class="mt-1 text-gray-600">
                Étant donné qu’un réseau est caractérisé par l’interdépendance de ses utilisateurs, un trouble ou un acte malveillant peut atteindre toute la communauté. Pour le bon fonctionnement de la plateforme et le respect de ses utilisateurs, ceux-ci sont invités à souscrire à un code de bonne conduite à respecter en matière d’utilisation de ladite plateforme.

                </p>

                <h2 class="font-semibold text-gray-900">1. Principes à respecter</h2>
                <h3 class="font-semibold text-gray-900 pl-3">1.1 Finalité de l’utilisation </h3>
                <p class="mt-1 text-gray-600">
                    L’accès à la plateforme est strictement personnel et incessible. Cet accès est à des fins professionnelles, à savoir enseignement, formation, recherche. À ce titre, est interdite toute utilisation des ressources publiées à des fins commerciales ou personnelles (autres que dans le cadre d’activités d’enseignement, de recherche ou de formation). Il est interdit à l’utilisateur de donner accès à titre commercial ou non, rémunéré ou non, à la plateforme à des tiers. 
                </p>
                <h3 class="font-semibold text-gray-900 pl-3">1.2 Utilisation loyale du réseau </h3>
                <p class="mt-1 text-gray-600">
                    Tout utilisateur est responsable de l’utilisation rationnelle des ressources de la plateforme à laquelle il a accès de manière à éviter toute consommation abusive et/ou détournée de ces ressources. 
                </p><p class="mt-1 text-gray-600">
                    Plus particulièrement, il doit : 
                    <ul class="list-disc pl-10">
                        <li>S’abstenir de toute utilisation malveillante destinée à en perturber ou à porter atteinte à son fonctionnement ; </li>
                        <li>L’utiliser de manière loyale en évitant de créer ou de générer des données ayant pour effet sa saturation du réseau ou encore d’en effacer les contenus</li>
                        <li>Signaler toute tentative de violation de son compte, ou d’intrusion sur son espace de travail.</li>
                    </ul>
                </p>



                <h3 class="font-semibold text-gray-900 pl-3">1.3 Licéité du contenu échangé </h3>
                <h4 class="font-semibold text-gray-900 pl-6">a. Respect du droit à la propriété « intellectuelle » </h4>
                <p class="mt-1 text-gray-600">
                    Les données publiées sur la plateforme doivent avoir été obtenues licitement et ne pas porter atteinte au droit des tiers.
                </p><p class="mt-1 text-gray-600">
                    L’utilisateur des ressources doit veiller au respect du droit de propriété d’autrui, et plus particulièrement il respecte les droits de propriété intellectuelle sur des œuvres protégées qui font interdiction d’utiliser, de reproduire et d’exploiter ces œuvres sans l’autorisation de l’auteur ou du titulaire des droits.
                </p>

                <h4 class="font-semibold text-gray-900 pl-6">b. Respect du droit des personnes </h4>
                <p class="mt-1 text-gray-600">
                    Il est interdit à tout utilisateur de porter atteinte à la vie privée d’autrui par un procédé quelconque et notamment par la transmission sans son consentement de son image ou de ses écrits diffusés à titre confidentiel ou privé.
                </p><p class="mt-1 text-gray-600">
                    De manière générale, l’utilisateur veille au respect de la personnalité, de l’intimité et de la vie privée d’autrui, y compris des mineurs (en particulier dans la communication d’informations relatives aux élèves). 
                </p>
                
                <h4 class="font-semibold text-gray-900 pl-6">c. Respect de l’ordre public</h4>
                <p class="mt-1 text-gray-600">
                    L’utilisateur agit dans le respect de l’ordre public et s’interdit notamment toute provocation à un acte malveillant de quelle que nature que ce soit (trouble à l’ordre public, incitation au racisme, incitation au terrorisme, incitation au suicide) ou toute diffusion de message à caractère violent de nature à porter atteinte à la dignité humaine. 
                </p>


                <h3 class="font-semibold text-gray-900 pl-3">1.3 Licéité du contenu échangé </h3>
                <p class="mt-1 text-gray-600">
                    L’utilisateur respecte les contenus à caractère confidentiel, et s’engage particulièrement : 
                    <ul class="list-disc pl-10">
                        <li>A ne pas lire, copier, divulguer ou modifier les fichiers d'un autre utilisateur sans y avoir été explicitement autorisé par son propriétaire et/ou son auteur, </li>
                        <li>A ne pas intercepter les communications entre tiers. </li>
                    </ul>
                </p>
                <h2 class="font-semibold text-gray-900">Engagement personnel de l’utilisateur </h2>
                <div class="flex items-center">
                    <input id="chartValidation" ref="inputCharte" @input="checkedCharte = !checkedCharte" name="chartValidation" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600 peer" />
                    <label for="chartValidation" class="ml-3 block text-sm leading-6 text-gray-900 peer-focus:underline">Je déclare avoir pris connaissance des dispositions de la présente charte, et m’engage à les respecter. </label>
                </div>
              <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button type="button" class="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto" @click="(e)=>{checkedCharte ? updateCharte(): inputCharte.focus()}">Je valide</button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

