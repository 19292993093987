<script setup lang="ts">
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid';

import { ref } from 'vue';

const props = defineProps<{
  name: string,
  id: string,
  label: string,
  values: any[],
  defaultValues?: any | any[] | undefined,
  multiple?: boolean,
  noBoldName?: boolean,
  required?: boolean
}>()

const selectedValue = props.multiple ? ref([]) : ref();

defineExpose({
  props,
  selectedValue
});

const dv = props.multiple ? ref([]) : ref();
if (props.defaultValues) {
  props.multiple ? props.defaultValues.map((val: any) => String(val)) : String(props.defaultValues)
  dv.value = props.multiple ? props.values.filter((val: any) => props.defaultValues.includes(String(val.id))) : props.values.find((val: any) => props.defaultValues.includes(val.id));
  selectedValue.value = dv.value
}
</script>

<template>
  <div :id="props.id">
    <Listbox name="assignee" as="div" v-model="selectedValue" class="w-full" :multiple="props.multiple">
      <ListboxLabel class="block text-sm font-medium leading-6 text-gray-900"><b v-if="props.required"
          class="text-red-600">*</b><b v-if="!props.noBoldName">{{ props.name }}</b> {{ props.label }}</ListboxLabel>
      <div class="relative mt-2">
        <ListboxButton
          class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 sm:text-sm sm:leading-6">
          <div v-if="props.multiple">
            <span class="flex items-center">
              <span v-if="selectedValue.length > 0" class="ml-3 block pr-6 truncate">{{ selectedValue.map((disip: any) =>
                disip.name).join(', ') }}</span>
              <span v-else class="ml-3 block truncate text-gray-500"> Selectionner</span>
            </span>
            <input type="hidden" :id="props.id + props.name" :data-id="props.id"
              :value="selectedValue.map((disip: any) => disip.name).join(', ')" />
          </div>
          <div v-else>
            <span v-if="selectedValue" class="flex items-center">
              <img v-if="(selectedValue.avatar)" :src="selectedValue.avatar" alt=""
                class="h-5 w-5 flex-shrink-0 rounded-full" />
              <span class="ml-3 block truncate">{{ selectedValue.name }}</span>
              <input type="hidden" :id="props.id + props.name" :data-id="props.id" :value="selectedValue.name" />

            </span>
            <span v-else class="flex items-center">
              <span class="ml-3 block truncate text-gray-500"> Selectionner </span>
            </span>
          </div>

          <span class="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
            <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            <kbd v-if="props.multiple && selectedValue.length > 0"
              class="inline-flex items-center justify-center rounded border border-blue-400 px-1 font-sans font-bold text-xs text-blue-400">
              <CheckIcon class="h-4 w-4" aria-hidden="true" />{{ selectedValue.length }}
            </kbd>
          </span>
        </ListboxButton>

        <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100"
          leave-to-class="opacity-0">
          <ListboxOptions
            class="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            <ListboxOption as="template" v-for="val in props.values" :key="val.id" :value="val"
              v-slot="{ active, selected }">
              <li
                :class="[active ? 'bg-blue-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                <div class="flex items-center">
                  <img v-if="(val.avatar)" :src="val.avatar" alt="" class="h-5 w-5 flex-shrink-0 rounded-full" />
                  <span :class="[selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate']">{{ val.name
                    }}</span>
                </div>

                <span v-if="selected"
                  :class="[active ? 'text-white' : 'text-blue-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                  <CheckIcon class="h-5 w-5" aria-hidden="true" />
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </div>
    </Listbox>
  </div>
</template>