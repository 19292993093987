<script setup lang="ts">
// import ToggleComponent from '@/components/inputs/ToggleComponent.vue';
import { useUser } from '@/stores/user';
import router from '@/router';
import { useForumStore } from '@/stores/iframes';

const { getUser } = useUser();

function t() {

    let url = import.meta.env.VITE_FORUM_URL + 'u/' + getUser().profile.sub + '/preferences/emails';
    let iframeForum: HTMLIFrameElement | null = document.querySelector("iframe#forum");
    if (iframeForum) {
        iframeForum.contentWindow?.postMessage(
            "notifcationPage",
            "https://forum.profan-transfert.limos.fr"
        );
    } else {
        const store = useForumStore();
        store.url = url
    }
    router.push({ name: 'Forum' });
}


</script>



<template>

    <div class="grid w-full md:max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
        <div class="w-full lg:w-80">
            <h2 class="text-base font-semibold leading-7 text-black">Notifications</h2>
            <p class="mt-1 text-sm leading-6 text-gray-400"></p>
        </div>

        <form class="md:col-span-2">
            <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                <div class=" flex flex-row items-center sm:col-span-6 justify-between w-full">
                    <label class="block text-sm font-medium leading-6 text-gray-900">Activer les notification par mail
                        du forum</label>

                    <button @click="t" type="button"
                        class="max-w-full rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                        Éditer
                    </button>
                </div>
            </div>


        </form>
    </div>

</template>