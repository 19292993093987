<script setup lang="ts">
import { Combobox,ComboboxButton,ComboboxLabel,ComboboxInput,ComboboxOptions,ComboboxOption,TransitionRoot } from '@headlessui/vue';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid';

import { computed, ref } from 'vue';

const props = defineProps<{
  name: string,
  id: string,
  label: string,
  values: any[],
  multiple?: boolean,
  defaultValues?: any,
  limit?: number,
  required?: boolean,
  showSubId?: boolean,
}>()

const selectedValue = props.multiple?ref([]):ref();

const query = ref('');
const filteredValues: any = computed(() =>
  query.value === ''
    ? props.values.slice(0, props.limit ? props.limit : undefined)
    : props.values.filter((val:any) => {
      return val.name
          .toLowerCase()
          .startsWith(query.value.toLowerCase().split(',').pop()!.trim())
      }).slice(0, props.limit ? props.limit : undefined)
)

const dv = props.multiple?ref([]):ref();
if(props.defaultValues){
  props.multiple ?props.defaultValues.map((val:any) =>  String(val)):String(props.defaultValues)
  dv.value = props.multiple ? props.values.filter((val:any) =>  props.defaultValues.includes(String(val.id))) : props.values.find((val:any) =>  props.defaultValues.includes(val.id));
  selectedValue.value = dv.value
}

defineExpose({
   props,
   selectedValue
});
</script>

<template>
  <div :id="String(props.id)">
  <Combobox as="div" v-model="selectedValue" name="assignee" :multiple="props.multiple">
    <ComboboxLabel class="block text-sm font-medium leading-6 text-gray-900"><b v-if="props.required" class="text-red-600">*</b> <b>{{ props.name }}</b> {{ props.label }}
    </ComboboxLabel>

    <div class="relative mt-2">
      <div
        class="relative w-full cursor-default overflow-hidden rounded-md bg-white text-left shadow-sm sm:text-sm sm:leading-6">
        <ComboboxInput v-if="!props.multiple"
          class="w-full border-none rounded-md py-2 pl-6 pr-20 text-sm leading-5 text-gray-900 ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-inset focus:ring-2 focus:blue-indigo-500"
          :displayValue="(item: any) => item.name" @change="query = $event.target.value"
          placeholder="Rechercher" :data-id="props.id" :data-value="selectedValue"/>
        <ComboboxInput v-else
          class="w-full border-none rounded-md py-2 pl-6 pr-20 truncate text-sm leading-5 text-gray-900 ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-inset focus:ring-2 focus:blue-indigo-500"
          :displayValue="(item: any) => item.map((disip: any) => disip.name).join(', ')+(item.length > 0 ? ',':'')" @change="query = $event.target.value"
          placeholder="Rechercher" :data-id="props.id" :data-value="selectedValue"/>
        <ComboboxButton class="absolute inset-y-0 right-0 flex items-center pr-2">
          <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
          <kbd v-if="props.multiple && selectedValue.length > 0" class="inline-flex items-center justify-center rounded border border-blue-400 px-1 font-sans font-bold text-xs text-blue-400">
            <CheckIcon class="h-4 w-4" aria-hidden="true" />{{ selectedValue.length }}</kbd>
        </ComboboxButton>
      </div>
      <TransitionRoot leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0"
        @after-leave="query = ''">
        <ComboboxOptions
          class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          <div v-if="filteredValues.length === 0 && query !== ''"
            class="relative cursor-default select-none py-2 px-4 text-gray-700">
            Aucun résultat
          </div>

          <ComboboxOption v-for="num in filteredValues" as="template" :key="props.id+num.id" :value="num"
            v-slot="{ selected, active }">
            <li class="relative cursor-pointer select-none py-2 pl-10 pr-4 group" :class="{
              'bg-blue-600 text-white': active,
              'text-gray-900': !active,
            }">
              <span class="block truncate whitespace-normal" :class="{ 'font-medium': selected, 'font-normal': !selected }">
                {{ num.name }} <em v-if="props.showSubId" :class="{'text-gray-100': active}" class="text-gray-500 group-hover:text-gray-100 group-focus-within:text-gray-100">@{{ num.id }}</em>
              </span>

              <span v-if="selected" class="absolute inset-y-0 left-0 flex items-center pl-3"
                :class="{ 'text-white': active, 'text-blue-600': !active }">
                <CheckIcon class="h-5 w-5" aria-hidden="true" />
              </span>
            </li>
          </ComboboxOption>
        </ComboboxOptions>
      </TransitionRoot>
    </div>
  </Combobox>
</div>
</template>